.rheostat.rheostat-horizontal {
  position: relative;
  right: 12px;
  margin: 20px 15px;
}

.rheostat-progress {
  background-color: red;
  position: absolute;
}

.rheostat-background {
  left: 12px;
}

.rheostat-handle {
  top: -6px;
  border-radius: 50% !important;
  height: 15px;
  width: 15px;
}
