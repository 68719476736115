.ReactVirtualized__Grid,
.ReactVirtualized__Grid *:focus {
  outline: 0;
}

.ReactVirtualized__Grid__headerRow {
  overflow: visible !important;
  align-items: flex-end !important;
  text-transform: unset !important;
  border-bottom: solid 1px var(--border) !important;
  z-index: 1;
  position: relative;
  font-weight: 500 !important;
}

.ReactVirtualized__Grid__headerTruncatedText {
  font-size: 14px;
  color: var(--dark);
}

.ReactVirtualized__Grid__cell > * {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Grid__innerScrollContainer > .ReactVirtualized__Grid__row:first-child {
  border-top: solid 1px transparent;
}

.ReactVirtualized__Grid.ReactVirtualized__Grid__bottomLeft > .ReactVirtualized__Grid__innerScrollContainer {
  width: unset !important;
  max-width: unset !important;
}

.ReactVirtualized__Grid.ReactVirtualized__Grid__topLeft > .ReactVirtualized__Grid__innerScrollContainer {
  width: unset !important;
  max-width: unset !important;
}

.ReactVirtualized__Grid__scheme__hoverable {
  background-color: var(--primaryLightAlpha);
  /* padding-left: 0 !important; */
}
