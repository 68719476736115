.ui.floating.visible.dropdown.multiSelect .menu {
  margin: 0 !important;
  top: -7px;
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride {
  box-shadow: none !important;
  display: block !important;
  border: none !important;
  position: relative !important;
  margin: 0 !important;
}

.ui.floating.visible.dropdown.multiSelect .menu .header {
  margin: 0.5rem 0 0 0 !important;
}

.ui.floating.visible.dropdown.multiSelect .menu .input {
  margin: 0.642857rem 0.685714rem !important;
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .item {
  cursor: default !important;
  display: flex !important;
  padding-right: 0 !important;
  justify-content: space-between !important;
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .item:hover {
  cursor: pointer !important;
  background-color: rgba(235,243,250,1);
  color: rgba(68,108,179,1);
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .item.active {
  font-weight: 400 !important;
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .menu.subMenuOverride .item {
  cursor: pointer !important;
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .menu.subMenuOverride {
  display: none !important;
  border: none !important;
  margin: 0 0 0 0.1em !important;
  position: absolute !important;
}

.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .item:hover .menu.subMenuOverride,
.ui.floating.visible.dropdown.multiSelect .menu.menuOverride .menu.subMenuOverride:hover {
  display: block !important;
}