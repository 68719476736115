.global-loader {
    overflow: hidden;
    flex: 0 0 auto;
    background: linear-gradient(rgb(62, 77, 102), rgb(0, 0, 12));
}
.global-loader.horizontal {
    width: 100%;
    height: 4px;
}
.global-loader.vertical {
    height: 100%;
    width: 4px;
}

.determinate {
    position: absolute;
    bottom: 0;
    transition: width 500ms ease-out 1s, height 500ms ease-out 1s;
    background-color: var(--primaryAccent);
}
.horizontal .determinate {
    max-width: 100%;
    height: 100%;
}
.vertical .determinate {
    max-height: 100%;
    width: 100%;
}

.indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
}
.indeterminate:before {
    content: '';
    position: absolute;
    background-color: var(--primaryAccent);
}
.indeterminate:after {
    content: '';
    position: absolute;
    background-color: var(--primaryAccent);
}
.horizontal .indeterminate:before {
    height: 100%;
    animation: indeterminate-first-horizontal 1.5s infinite ease-out;
}
.horizontal .indeterminate:after {
    height: 100%;
    animation: indeterminate-second-horizontal 1.5s infinite ease-in;
}
.vertical .indeterminate:before {
    width: 100%;
    animation: indeterminate-first-vertical 1.5s infinite ease-out;
}
.vertical .indeterminate:after {
    width: 100%;
    animation: indeterminate-second-vertical 1.5s infinite ease-in;
}


@keyframes indeterminate-first-horizontal {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}

@keyframes indeterminate-second-horizontal {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}


@keyframes indeterminate-first-vertical {
    0% {
        bottom: -100%;
        height: 100%;
    }
    100% {
        bottom: 100%;
        height: 10%;
    }
}

@keyframes indeterminate-second-vertical {
    0% {
        bottom: -150%;
        height: 100%;
    }
    100% {
        bottom: 100%;
        height: 10%;
    }
}